<template>
  <div>
    <c-table-feedback
      :title="title"
      :items="getItems"
      :columns="columns"
      :totalRows="totalRows"
      :perPage="perPage"
      :currentPage="currentPage"
      :api="api"
      :filterCourse="true"
      @changeCurrentPage="changeCurrentPage"
      @changePerPage="changePerPage"
    ></c-table-feedback>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import CTableFeedback from "@/components/CTableFeedback";

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CTableFeedback,
  },
  data() {
    return {
      title: "Kuesioner",
      api: "questionnaire-students/admin",
      dir: false,
      items: [],
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "blockname", label: "nama blok", sortable: true },
        { key: "lecturername", label: "dosen pengajar", sortable: true },
        { key: "year", label: "tahun ajaran", sortable: true },
        { key: "semester", label: "semester", sortable: true },
        { key: "status", label: "status", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
          actions: { active: true, complete: true },
        },
      ],
    };
  },
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
  },
};
</script>
