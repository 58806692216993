export default {
  getItems(context, callback, $self) {
    const _ = $self;
    // console.log(context);
    const sortBy = context.sortDesc == true ? "desc" : "asc";
    const orderBy = context.sortBy;
    const params = {
      take: _.perPage,
      skip: _.limit,
      orderBy: `${orderBy}.${sortBy}`,
    };

    const options = Object.assign(params, { ...context.filter });
    const newOptions = {};
    Object.entries(options).forEach((row) => {
      const [key, value] = row;
      if (value !== null && value !== "") {
        newOptions[key] = value;
      }
    });

    _.$axios.get(_.api, { params: newOptions }).then((res) => {
      if (res.data.success) {
        const consume = res.data.data;
        _.totalRows = consume.count;
        _.page = consume.currentPage;
        _.perPage = consume.perPage;

        callback(consume.rows || []);
      }
    });
  },
  changeCurrentPage($self, page) {
    const _ = $self;
    _.currentPage = page;
    _.limit = (parseInt(page) - 1) * parseInt(_.perPage);
  },
  changePerPage($self, perPage) {
    const _ = $self;
    _.perPage = perPage;
  },
  destroy($self, id) {
    const _ = $self;
    _.$swal
      .fire({
        title: "Apakah kamu yakin ingin menghapus data?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      })
      .then((res) => {
        if (res.value) {
          _.$axios.delete(`${_.api}/${id}`).then((res) => {
            _.response = {
              variant: res.data.status ? "success" : "danger",
              message: res.data.message,
            };
            _.get();
          });
        }
      });
  },
};
